<template>
  <section id="projects">
    <h1 class="projects-header">Projects</h1>
    <span class="projects-desktop">
      <div v-for="(project, viz) in projects" :key="viz" class="single-project">
        <div class="project-description-container">
          <div class="project-header">
            <hgroup>
              <h2>Project Name</h2>
              <h1>{{ project.title }}</h1>
            </hgroup>
            <div>
              <h2>Description</h2>
              <p class="project-description">{{ project.description }}</p>
            </div>
            <div>
              <h2>Project Stack</h2>
              <ul class="stack-list">
                <li v-for="(lang, viz) in project.skills" :key="viz">{{ lang }}</li>
              </ul>
            </div>
            <div>
              <h2>Project Links</h2>
              <a :href="project.link" class="cta" target="_blank">Live Site</a>
              <a :href="project.github" target="_blank">
                <i class="fab fa-github"></i>
              </a>
            </div>
          </div>
          <img
            v-for="(image, viz) in project.images"
            :key="viz"
            :src="require(`@/assets/${image.url}`)"
            :alt="image.alt"
          />
        </div>
      </div>
    </span>
    <div
      v-for="(project, viz) in visibleProject"
      :key="viz"
      class="single-project mobile"
    >
      <div class="project-header">
        <hgroup>
          <h2>Project Name</h2>
          <h1>{{ project.title }}</h1>
        </hgroup>
        <div>
          <h2>Description</h2>
          <p class="project-description">{{ project.description }}</p>
        </div>
        <div>
          <h2>Project Stack</h2>
          <ul class="stack-list">
            <li v-for="(lang, viz) in project.skills" :key="viz">{{ lang }}</li>
          </ul>
        </div>
        <div>
          <h2>Project Links</h2>
          <a :href="project.link" class="cta" target="_blank">Live Site</a>
          <a :href="project.github" target="_blank">
            <i class="fab fa-github"></i>
          </a>
        </div>
      </div>
      <div class="image-container">
        <img
          v-for="(image, viz) in project.imagesMobile"
          :key="viz"
          :src="require(`@/assets/${image.url}`)"
          :alt="image.alt"
        />
      </div>
    </div>
    <pagination
      :projects="projects"
      :currentPage="currentPage"
      :page-size="pageSize"
      @page:update="updatePage"
    > 
    </pagination>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Pagination from "@/components/Pagination.vue";

const PROJECTS = [
  {
    title: "DELICIEUX",
    link: "http://delicieux.herokuapp.com/#/",
    github: "https://github.com/jashieh/delicieux",
    skills: ['React', 'JavaScript', 'Express', 'Node.js', 'HTML5', 'SCSS', 'MongoDB'],
    description:
      "MERN stack team-built meal planning web application where users are able to design, customize, and plan their weekly meals based on the ingredients they already have.",
    images: [
      {
        url: "deli-one.jpeg",
        alt: "Delicieux One",
      },
      {
        url: "deli-two.jpeg",
        alt: "Delicieux Two",
      },
      {
        url: "deli-three.jpeg",
        alt: "Delicieux Three",
      },
    ],
    imagesMobile: [
      {
        url: "deli-one.jpeg",
        alt: "Delicieux One",
      },
    ],
  },
  {
    title: "AESOP",
    link: "http://aesop-kelly.herokuapp.com/#/",
    github: "https://github.com/kellyk525/Aesop",
    skills: ['Ruby', 'Rails', 'React', 'Redux', 'HTML5', 'SCSS'],
    description:
      "Clone of Aesop.com, an E-commerce website that allows users to browse through and shop Aesop products",
    images: [
      {
        url: "aesop-onee.jpeg",
        alt: "Aesop One",
      },
      {
        url: "aesop-two.jpeg",
        alt: "Aesop Two",
      },
      {
        url: "aesop-three.jpeg",
        alt: "Aesop Three",
      },
    ],
    imagesMobile: [
      {
        url: "aesop-three.jpeg",
        alt: "Aesop Three",
      },
    ],
  },
  {
    title: "RECORDS",
    link: "https://kellyk525.github.io/Records/",
    github: "https://github.com/kellyk525/Records",
    skills: ['JavaScript', 'HTML5', 'CSS3', 'Canvas'],
    description: "Custom animated HTML5 Audio Analyser graphic",
    images: [
      {
        url: "record-four.png",
        alt: "Record One",
      },
      {
        url: "record-five.png",
        alt: "Record Two",
      },
      {
        url: "record-seven.png",
        alt: "Record Three",
      },
    ],
    imagesMobile: [
      {
        url: "record-five.png",
        alt: "Record Two",
      },
    ],
  },
];

export default {
  name: "ProjectSection",
  data() {
    return {
      projects: PROJECTS,
      currentPage: 0,
      pageSize: 3,
      visibleProject: [],
    };
  },
  mounted: function () {
    this.updateVisibleProjects();
  },
  methods: {
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updateVisibleProjects()
    },
    updateVisibleProjects() {
      // this.visibleProject = this.projects.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      this.visibleProject = this.projects.slice(
        this.currentPage,
        this.currentPage + 1
      );

      // if we have 0 visible projects, go back a page
      if (this.visibleProject.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    }
  },
  components: {
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin: 28px 0px;
  font-size: 60px;
  letter-spacing: 0.2px;
  color: white;
}

#projects {
  // height: calc(100vh - 58px);
  max-width: 1300px;
  padding-left: 40px;
  margin-right: 40px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(179, 179, 179);
  background: #46413E;
}

.single-project {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px;

  h1 {
    margin: 0px;
    margin-top: 9px;
    font-size: 44px;
    line-height: 50px;
  }

  h6 {
    font-size: 13px;
    letter-spacing: 0.2px;
    color: rgb(139, 139, 139);
    margin: 20px 0px 10px 0px;
  }

  p {
    margin: 0px;
  }

  a {
    padding: 6px 12px;
    margin-right: 10px;

    color: white;
    font-size: 14px;
    letter-spacing: 0.3px;
    text-decoration: none;
    border-radius: 10px;
    background: black;
    transition: all 0.5s;

    &:hover {
      background: #c7bba6;
    }

    i {
      color: white;
    }
  }
}

.project-description-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;

  img {
    width: 48.7%;
    margin-top: 35px;

    // width: 550px;
    // height: 470px;
    // border-radius: 16px;
    // box-shadow: 1px 7px 10px rgb(204, 203, 203);
  }

  .project-header {
    color: #0f141e;
    color: rgb(207, 207, 207);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 48%;

    h1 {
      // color: #0f141e;
    }


    h2 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 0px;
      padding: 5px 0px;
      text-transform: uppercase;
      letter-spacing: 6.5px;
      color: #878a8f;
    }
    
    ul.stack-list {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      
      li {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.single-project.mobile {
  display: none;
}

@media screen and (max-width: 880px) {
  .single-project.mobile {
    display: block;
  }

  .project-header {
    h2 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 0px;
      padding: 5px 0px;
      text-transform: uppercase;
      letter-spacing: 6.5px;
      color: #878a8f;
    }
  }

  .projects-header {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .projects-desktop {
    display: none;
  }

  #projects {
    min-width: unset;
    max-width: unset;
    padding: 0px 18px;
    margin-right: 0px;
    margin-bottom: 100px;
  }

  p {
    margin-bottom: 30px;
  }

  .image-container {
    margin-top: 35px;

    img {
      display: block;
      width: 70%;
      min-width: 260px;
      margin: 0 auto;
    }
  }
}
</style>
