<template>
  <div class="page-container">
    <main class="page-container-inner">
      <h2 class="main-text">KELLY J. KU</h2>
      <p class="switching-text">{{ characteristics[0] }}</p>
      <div class="main-text-wrapper">
        <h1 class="main-position">Software Engineer</h1>
        <p class="main-description" >San Francisco based software engineer with a diverse professional background ranging from the innovative blockchain space, mission-driven EdTech field, and client-oriented hospitality industry.</p>
        <p class="main-links"><router-link to="/projects" class="main-link first">View Projects</router-link> or <router-link to="/about" class="main-link">Read My Story</router-link></p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "New",
  data() {
    return {
      characteristics: ['I am a software developer', 'I enjoy building user friendly websites', 'I am meticulous', 'I am original']
    }
  },
  mounted() {
    window.setInterval(() => {
      this.pollCharacteristics();
    }, 2600);
  },
  methods: {
    pollCharacteristics() {
      const first = this.characteristics.shift();
      this.characteristics = this.characteristics.concat(first);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  color: white;
  background: #516172;
  // background: #4c5a6b;
  // background: #485665;
  font-weight: 500;
  flex: 1;

  h1 {
    font-size: 200px;
    color: #A8BDC7;
    text-shadow: 5px 7px 10px #9BB5BF;

  }
}

.page-container-inner {
  height: 100%;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    width: 150px;
    height: 180px;
    border-radius: 30%;
  }
}

h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0px;
  padding-left: 34px;
  text-transform: uppercase;
  letter-spacing: 6.5px;
  color: #9ca1a8;
}

h1.main-position {
  color: black;
  font-size: 62px;
  text-shadow: none;
  padding: 5px 0px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.main-text-wrapper {
  margin-bottom: 90px;
  padding-left: 34px;
}

.main-description {
  font-size: 18px;
  line-height: 30px;
}

.main-links {
  margin: 0px;
  margin-top: 20px;
}

.main-link {
  text-decoration: none;
  color: white;
  margin: 0px 10px;
  font-weight: 600;
  font-size: 18px;
  transition: 0.3s ease;
}

.main-link:hover {
  color: rgb(173, 147, 0);
}

.main-link.first {
  margin-left: 0px;
}

.switching-text {
  background: black;
  padding: 10px;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px;
  margin-bottom: 20px;
  padding-left: 34px;
  padding-right: 34px;
  width: fit-content;
}

@media screen and (max-width: 880px) {

  .page-container {
    padding-top: 40px;
  }

  h2 {
    padding-left: 18px;
    padding-right: 18px;
  }

  .switching-text {
    padding: 9px 18px;
    line-height: 24px;
    margin-right: 18px;
  }

  h1.main-position {
    font-size: 42px;
    line-height: 52px;
  }

  .main-text-wrapper {
    padding: 0px 18px;

    .main-description {
      font-size: 16px;
    }

    .main-links,
    .main-link {
      font-size: 16px;
      line-height: 26px;
    }
  }

}
</style>