<template>
  <div v-if="totalPages > 0" class="pagination-wrapper">
    <span
      v-if="showPreviousLink()"
      class="pagination-btn left"
      @click="updatePage(currentPage - 1)"
    ><i class="fas fa-chevron-left"></i>
    </span>
    {{ currentPage + 1 }} of {{ totalPages }}
    <span
      v-if="showNextLink()"
      class="pagination-btn right"
      @click="updatePage(currentPage + 1)"
    ><i class="fas fa-chevron-right"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["projects", "currentPage", "pageSize"],
  computed: {
    totalPages: function () {
      // return Math.ceil(this.projects.length / this.pageSize);
      return this.pageSize;
    },
  },
  methods: {
    updatePage(pageNumber) {
      this.$emit("page:update", pageNumber)
    },
    showPreviousLink() {
      return this.currentPage === 0 ? false : true;
    },
    showNextLink() {
      return this.currentPage === this.totalPages - 1 ? false : true;
    },
  }
}
</script>
 
<style lang="scss" scoped>
.pagination-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  color: rgb(207, 207, 207);
}

.pagination-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
  background: rgb(2, 2, 2);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background: rgb(204, 184, 147);
  }
}

.left {
  margin-right: 13px;
}

.right {
  margin-left: 13px;
}

@media screen and (max-width: 880px) {
  .pagination-wrapper {
    display: flex;
  }
}
</style>