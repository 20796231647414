<template>
  <section id="about">
    <h1>About Me</h1>
    <h2>Kelly Jihye Ku</h2>
    <div class="about-content">
      <img :src="require('@/assets/profile.jpeg')" alt="Kelly Ku" />
      <div>
        <p>
          As I learned more about the tech industry, I became more immersed in this
          field and decided to make a career change to learn computer science and
          programming. Within a span of months, I was able to grasp technologies
          such as Ruby, JavaScript, React, Redux, and other technologies. I am
          constantly working to continue developing these skillsets by immersing
          myself with projects that I am passionate about. With an interest in both
          programming and design, I strive to translate my creativity into code and
          create captivating user experiences.
        </p>
        <p>
          While I am comfortable working independently, I am looking for an
          enthusiastic team to collaborate with and learn from, as I find I work
          best when surrounded by a team in which I can grow personally while
          contributing to a larger group.
        </p>
        <p>
          Outside of programming, I enjoy drawing, cooking, attending concerts, and
          going to the gym in order to maintain a healthy work-life balance which in
          turn fuels my creativity.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "AboutSection",
  components: {},
};
</script>
<style lang="scss" scoped>
h1 {
  color: white;
  margin: 28px 0px;
  font-size: 60px;
  letter-spacing: 0.2px;
  line-height: 80px;
}

#about {
  // height: calc(100vh - 58px);
  padding-left: 40px;
  margin: 0px;
  margin-right: 40px;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  color: #cfcfcf;
  font-size: 16px;
  line-height: 26px;

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 5px 0px;
    text-transform: uppercase;
    letter-spacing: 6.5px;
    color: #878a8f;
  }
}

.about-content {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 200px;
    height: 300px;
    margin-right: 30px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 880px) {
  #about {
    min-width: unset;
    max-width: unset;
    height: unset;
    margin-right: 0px;
    margin-bottom: 130px;
    padding: 0px 18px;

    h1 {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px;
    }
  }

  .about-content {
    flex-direction: column;

    img {
      margin: 20px 0px 20px 0px;
      width: 160px;
      height: 240px;
    }
  }
}
</style>
