import Vue from "vue";
import VueRouter from "vue-router";
import New from "../views/New.vue";
import AboutSection from '../components/AboutSection.vue'
import ProjectSection from '../components/ProjectSection.vue'
import ContactSection from '../components/ContactSection.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "New",
    component: New,
  },
  {
    path: "/about",
    name: 'AboutSection',
    component: AboutSection,
  },
  {
    path: "/projects",
    name: "ProjectSection",
    component: ProjectSection,
  },
  {
    path: "/contact",
    name: "ContactSection",
    component: ContactSection,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
