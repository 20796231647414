<template>
  <section id="contact">
    <h1>Get in touch</h1>
    <h2>Let's work together!</h2>
    <p>
      I am currently looking for a full-time software engineering position.
      Even if its not a job-related opportunity, my inbox is always open!
      Whether it's for answering a question or just to say hello, I'll do my
      best to respond as quickly and diligently as I can.
    </p>
    <h2>Contact Information</h2>
    <div class="social-links-container">
      <div class="social-box">
        <h2>Github</h2>
        <a href="https://github.com/kellyk525" target="_blank">
          <i class="fab fa-github"></i>
          @kellyk525
        </a>
      </div>
      <div class="social-box">
        <h2>Email</h2>
        <a href="mailto:kjk224@cornell.edu">
          <i class="far fa-envelope-open"></i>
          kjk224@cornell.edu
        </a>
      </div>
      <div class="social-box">
        <h2>LinkedIn</h2>
        <a
          href="https://www.linkedin.com/in/kelly-ku-642b49130/"
          target="_blank">
          <i class="fab fa-linkedin-in"></i>
          Kelly Jihye Ku
        </a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "ContactSection",
  components: {},
};
</script>
<style lang="scss" scoped>
h1 {
  color: white;
  margin: 28px 0px 28px 0px;
  line-height: 80px;
  font-size: 60px;
  letter-spacing: 0.2px;
}

h2 {
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 5px 0px;
  text-transform: uppercase;
  letter-spacing: 6.5px;
  color: #878a8f;
}

#contact {
  padding-left: 40px;
  margin: 0px;
  margin-right: 40px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  color: #cfcfcf;
  font-size: 16px;
  line-height: 26px;
}

p {
  margin: 0px 0px 20px 0px;
}

.social-links-container {
  display: flex;
  justify-content: space-between;

  .social-box {
    width: 32%;
    color: rgb(59, 59, 59);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.507);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;

    h2 {
      margin: 0px;
      letter-spacing: 4px;
    }

    p {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin: 0px 0px 0px 0px;
    }

    a {
      color: #918979;
      background: black;
      text-decoration: none;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 6px 12px;
      transition: all 0.5s;

      &:hover {
        color: white;

        i {
          color: white;
        }
      }
    }

    i {
      font-size: 20px;
      color: #918979;
      margin-right: 12px;
      transition: all 0.5s;
    }
  }
}

@media screen and (max-width: 880px) {
  #contact {
    min-width: unset;
    max-width: unset;
    padding: 0px 18px;
    margin-bottom: 130px;
    margin-right: 0px;
  }

  h1 {
    font-size: 50px;
    margin-bottom: 20px;
    line-height: 58px;
  }

  .social-links-container {
    flex-direction: column;
    width: 100%;

    .social-box {
      width: 58%;
      min-width: 250px;
      max-width: 270px;
      margin-bottom: 20px;
    }
  }
}
</style>
