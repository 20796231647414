<template>
    <router-link :to="to" class="link" :class="{ active: isActive, 'link-open': open }">
        <i class="btn-icon" :class="icon" />
        <transition name="fade">
            <span v-if="open" class="side-nav-text">
                <slot />
            </span>
        </transition>
    </router-link>
</template>

<script>
// import { useRoute } from 'vue-router'

export default {
    data() {
        return {
            route: null
        };
     },
    props: {
        to: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            required: true,
            default: true
        }
    },
    computed: {
        isActive: function() {
            return this.$route.path === this.to
        }
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.face-leave-active {
    transition: opacity 3s;
}

// css when animation has finished
.fade-enter,
.face-leave-to {
    opacity: 0;
}

.link {
    // background: rgba(130, 149, 155, 0.678);
    border: 1px solid rgba(255, 255, 255, 0.308);
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    position: relative;
    font-weight: 400;
    user-select: none;

    margin: 6px 0;
    padding: 8px 0px 8px 0px;
    border-radius: 9px;
    height: 20px;
    color: white;
    text-decoration: none;
}

.link i {
    margin-left: 11px;
}

.link:hover {
    color: rgb(173, 147, 0);
    border-color: rgb(173, 147, 0);
}

.link.active {
    color: rgb(189, 161, 1);
    border-color: rgb(189, 161, 1);
}

.link .btn-icon {
    font-size: 15px;
    flex-shrink: 0;
    margin-right: 10px;
}

.active {
    color: red;
}

.side-nav-text {
    padding-right: 76px;
    font-size: 13px;
}
</style>