<template>
  <div id="app">
    <div class="side-nav" :style="{ width: sidebarWidth }">
      <nav class="side-nav-links">
        <sidebar-link to="/" icon="fas fa-home" :open="sideNavOpen" >Home</sidebar-link> 
        <sidebar-link to="/about" icon="fas fa-skating" :open="sideNavOpen">About</sidebar-link> 
        <sidebar-link to="/projects" icon="fas fa-project-diagram" :open="sideNavOpen">Projects</sidebar-link> 
        <sidebar-link to="/contact" icon="fas fa-question" :open="sideNavOpen">Contact</sidebar-link> 
        <button @click="openSideNav" :class="{ 'btn-open': sideNavOpen, btn: true }" ><i :class="{ 'fas': true, 'fa-angle-double-right': !sideNavOpen, 'fa-angle-double-left': sideNavOpen, 'icon': true }"></i></button>
      </nav>
      <div class="social-links">
        <div class="social-box">
          <a href="https://github.com/kellyk525" target="_blank">
            <i class="fab fa-github"></i>
          </a>
        </div>
        <div class="social-box">
          <a href="mailto:kjk224@cornell.edu">
            <i class="far fa-envelope-open"></i>
          </a>
        </div>
        <div class="social-box">
          <a
            href="https://www.linkedin.com/in/kelly-ku-642b49130/"
            target="_blank">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="copyright-label">© 2021 Kelly Ku · SF</div>
    <router-view />
  </div>
</template>

<script>
import SidebarLink from '@/components/SidebarLink.vue'

export default {
  name: "App",
  components: {
    SidebarLink
  },
  data() {
    return {
      sideNavOpen: false,
    }
  },
  computed: {
    sidebarWidth: function () {
      const sideBarWidth = 180;
      const sideBarWidthCollapsed = 54;
      return `${this.sideNavOpen ? sideBarWidth : sideBarWidthCollapsed }px`
    },
  },
  methods: {
    openSideNav() {
      this.sideNavOpen = !this.sideNavOpen
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

body {
  padding: 0px;
  margin: 0px;
}

#app {
  font-family: "Noto Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background: rgb(1, 12, 44);
  // background: #B9DADF;
  background: #46413E;
  min-height: 100vh;
  display: flex;
}

.copyright-label {
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 10px 20px;
  opacity: 0.9;
  backdrop-filter: blur(30px);
   -webkit-backdrop-filter: blur(30px);
  color: white;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.3);
}

button, input[type="submit"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.icon {
  color: rgb(122, 119, 119);
}

.btn {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  border-radius: 50%;
  border: 1px solid rgb(122, 119, 119);
  transition: 1s ease;
}

.btn:hover,
.btn:focus {
  border-color: white;
  
  .icon {
    color: white;
  }
}

.side-nav {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  box-sizing: border-box;
  padding: 50px 0px 0px 0px;
  background: #8FAFB7;
  background: black;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s ease;

  .side-nav-menu i {
    color: white;
  }

  .side-nav-links {
    display: flex;
    flex-direction: column;
    margin: 0px 7px;
  }

  .social-links {
    margin-left: 6px;
    
    .social-box {
      margin: 12px 0px;
      transition: 0.3s ease;
      
      a {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border: 1px solid rgb(122, 119, 119);;
        // box-shadow: 1px 4px 10px rgb(119, 147, 153);
      }

      i {
        font-size: 17px;
        color: rgb(122, 119, 119);
      }

      &:hover {
        a {
          border-color: white;
        }

        i {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  #app {
    flex-direction: column;
  }

  .side-nav {
    height: unset;
    width: 100% !important;
    padding: 10px 10px;
    flex-direction: row;

    .side-nav-links {
      flex-direction: row;

      .btn {
        display: none;
      }

      .link {
        margin-right: 14px;

        .side-nav-text {
          color: red;
          display: none;
        }
      }
    }
  }

  .social-links {
    display: none;
  }

}

#nav {
  background: #FAF5EF;
  padding: 18px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  text-align: right;
  z-index: 100;

  a {
    color:#928c7f;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    font-family: Arial;
    letter-spacing: 1.2px;
    margin-left: 20px;

    &:hover {
      color: rgb(75, 74, 74);
    }

    &:active {
      color: rgb(75, 74, 74);
    }
  }
}
</style>
